import { trackPromise } from 'react-promise-tracker';

export const reserveSingleItem = async (itemId, cartId, countryCode, quantity) => {
    return trackPromise(
     fetch("/.netlify/functions/kuzzleCheckoutReserveSingleItems", {
        method: "POST",
        body: JSON.stringify({
            itemId,
            cartId,
            quantity,
            countryCode
        }),
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    })
        .then((data) => {
            status = data.status;
            return data.json()
        })
        .then(async (response) => {
            console.log('response', response)
            if (status === '200') {
                return {
                    reserved: response.msg.reserved,
                    clearPreviousCart: response.msg.clearPreviousCart,
                    isAvailable: response.msg.isAvailable,
                    newCartItems: response.msg.newCartItems,
                    cartId: response.msg.cartId,
                    id: response.msg.id
                };
            } 
                return {
                    reserved: false,
                    isAvailable: false
                };
            
        })
        .catch((err) => {
            console.error('Error occured while trying to get data from kuzzleCheckoutReserveSingleItems', err)
            return {
                reserved: false,
                isAvailable: false
            };
        })    
    );
}


export const manageSingleItems = async (itemId, cartId, countryCode, quantity) => {
    return trackPromise(
      fetch("/.netlify/functions/kuzzleCheckoutManageSingleItems", {
        method: "POST",
        body: JSON.stringify({
            itemId,
            cartId,
            quantity,
            countryCode
        }),
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    })
        .then((data) => {
            status = data.status;
            return data.json()
        })
        .then(async (response) => {
            console.log('response', response)
            if (status === '200') {
                return {
                    updated: response.msg.updated,
                    isAvailable: response.msg.isAvailable,
                    clearPreviousCart: response.msg.clearPreviousCart,
                    newItems: response.msg.newItems,
                    cartId: response.msg.cartId,
                    itemId: response.msg.itemId
                };
            } 
                return {
                    updated: false
                };
            
        })
        .catch((err) => {
            console.error('Error occured while trying to get data from kuzzleCheckoutReserveItems', err)
            return {
                updated: false
            };
        })  
    )
}

export const reserveItems = async (productCombinationId, cartId) => {
    return trackPromise(
     fetch("/.netlify/functions/kuzzleCheckoutReserveItems", {
        method: "POST",
        body: JSON.stringify({
            productCombinationId,
            cartId,
            whitelistBikeLockId: localStorage.getItem('IMEI') || null
        }),
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    })
        .then((data) => {
            status = data.status;
            return data.json()
        })
        .then(async (response) => {
            console.log('response', response)
            if (status === '200') {
                // Prevent trying to add imei bike lock another time, after it is already added
                localStorage.removeItem('IMEI');
                return {
                    reserved: response.msg.reserved,
                    clearPreviousCart: response.msg.clearPreviousCart,
                    cartId: response.msg.cartId,
                    ids: response.msg.ids
                };
            } 
                return {
                    reserved: false
                };
            
        })
        .catch((err) => {
            console.error('Error occured while trying to get data from kuzzleCheckoutReserveItems', err)
            return {
                reserved: false
            };
        })    
    );
}

export const releaseItems = async (productCombinationId, cartId, type) => {
    return trackPromise(
      fetch("/.netlify/functions/kuzzleCheckoutReleaseItems", {
        method: "POST",
        body: JSON.stringify({
            productCombinationId,
            cartId,
            type
        }),
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    })
        .then((data) => {
            status = data.status;
            return data.json()
        })
        .then(async (response) => {
            console.log('response', response)
            if (status === '200') {
                return {
                    release: response.msg.release,
                    cartId: response.msg.cartId,
                    ids: response.msg.ids
                };
            } 
                return {
                    release: false
                };
            
        })
        .catch((err) => {
            console.error('Error occured while trying to get data from kuzzleCheckoutReserveItems', err)
            return {
                release: false
            };
        })  
    )
}
